import Rider from "../model/Rider";

export const riders = [
  new Rider("Ben O'Conner", "AG2R", "Australia", 1.88, new Date(1995, 11, 25)),
  new Rider(
    "Greg Van Avermaet",
    "AG2R",
    "Belgium",
    1.81,
    new Date(1985, 5, 17)
  ),
  new Rider(
    "Joe Dombrowski",
    "Astana",
    "United States",
    1.85,
    new Date(1991, 5, 12)
  ),
  new Rider(
    "Miguel Angel Lopez",
    "Astana",
    "Columbia",
    1.64,
    new Date(1994, 2, 4)
  ),
  new Rider("Pello Bilbao", "Bahrain", "Spain", 1.74, new Date(1990, 2, 25)),
  new Rider(
    "Wout Poels",
    "Bahrain",
    "Netherlands",
    1.83,
    new Date(1987, 10, 1)
  ),
  new Rider("Jack Haig", "Bahrain", "Australia", 1.9, new Date(1993, 9, 6)),
  new Rider("Mikel Landa", "Bahrain", "Spain", 1.73, new Date(1989, 12, 13)),
  new Rider("Sam Bennett", "Bora", "Ireland", 1.78, new Date(1990, 10, 16)),
  new Rider("Jai Hindley", "Bora", "Australia", 1.75, new Date(1996, 5, 5)),
  new Rider("Aleksandr Vlasov", "Bora", "Russia", 1.86, new Date(1996, 4, 23)),
  new Rider("Lennard Kamna", "Bora", "Germany", 1.81, new Date(1996, 9, 9)),

  new Rider(
    "Guillaume Martin",
    "Cofidis",
    "France",
    1.73,
    new Date(1993, 6, 9)
  ),
  new Rider("Hugh Carthy", "EF", "Great Britain", 1.93, new Date(1994, 7, 9)),
  new Rider("Magnus Cort", "EF", "Denmark", 1.83, new Date(1993, 1, 16)),
  new Rider("Alex Howes", "EF", "United States", 1.8, new Date(1988, 1, 1)),
  new Rider(
    "Neilson Powless",
    "EF",
    "United States",
    1.83,
    new Date(1996, 9, 3)
  ),
  new Rider("Rigoberto Uran", "EF", "Columbia", 1.73, new Date(1987, 1, 26)),
  new Rider("Arnaud Demare", "Groupama", "France", 1.82, new Date(1991, 8, 26)),
  new Rider("David Gaudu", "Groupama", "France", 1.73, new Date(1996, 10, 10)),
  new Rider("Thibaut Pinot", "Groupama", "France", 1.8, new Date(1990, 5, 29)),
  new Rider("Egan Bernal", "Ineos", "Columbia", 1.75, new Date(1997, 1, 13)),
  new Rider("Richard Carapaz", "Ineos", "Equador", 1.7, new Date(1993, 5, 29)),
  new Rider(
    "Tao Geoghegan Hart",
    "Ineos",
    "Great Britain",
    1.83,
    new Date(1995, 3, 30)
  ),
  new Rider(
    "Ethan Hayter",
    "Ineos",
    "Great Britain",
    1.78,
    new Date(1998, 9, 18)
  ),
  new Rider(
    "Daniel Felipe Martinez",
    "Ineos",
    "Columbia",
    1.72,
    new Date(1996, 4, 25)
  ),
  new Rider(
    "Thomas Pidcock",
    "Ineos",
    "Great Britain",
    1.7,
    new Date(1999, 6, 30)
  ),
  new Rider(
    "Geraint Thomas",
    "Ineos",
    "Great Britain",
    1.83,
    new Date(1986, 5, 25)
  ),
  new Rider(
    "Dylan van Baarle",
    "Ineos",
    "Netherlands",
    1.83,
    new Date(1992, 5, 21)
  ),
  new Rider("Cameron Wurf", "Ineos", "Australia", 1.71, new Date(1983, 8, 3)),
  new Rider("Adam Yates", "Ineos", "Great Britain", 1.73, new Date(1992, 9, 7)),
  new Rider(
    "Biniam Girmay",
    "Intermarche",
    "Eritrea",
    1.84,
    new Date(2000, 4, 2)
  ),
  new Rider(
    "Jan Hirt",
    "Intermarche",
    "Czech Republic",
    1.84,
    new Date(2000, 4, 2)
  ),
  new Rider(
    "Taco van der Hoorn",
    "Intermarche",
    "Netherlands",
    1.87,
    new Date(1993, 12, 4)
  ),
  new Rider(
    "Chris Froome",
    "Israel",
    "Great Britain",
    1.86,
    new Date(1985, 5, 20)
  ),
  new Rider("Jakob Fuglsang", "Israel", "Denmark", 1.81, new Date(1985, 3, 22)),
  new Rider("Michael Woods", "Israel", "Canada", 1.75, new Date(1986, 10, 12)),
  new Rider(
    "Tiesj Benoot",
    "Jumbo-Visma",
    "Belgium",
    1.9,
    new Date(1994, 3, 11)
  ),
  new Rider(
    "Rohan Dennis",
    "Jumbo-Visma",
    "Australia",
    1.82,
    new Date(1990, 5, 28)
  ),
  new Rider(
    "Tom Dumoulin",
    "Jumbo-Visma",
    "Netherlands",
    1.85,
    new Date(1990, 11, 5)
  ),
  new Rider(
    "Steven Kruijswijk",
    "Jumbo-Visma",
    "Netherlands",
    1.78,
    new Date(1987, 6, 7)
  ),
  new Rider(
    "Sepp Kuss",
    "Jumbo-Visma",
    "United States",
    1.8,
    new Date(1984, 9, 13)
  ),
  new Rider(
    "Primoz Roglic",
    "Jumbo-Visma",
    "Slovenia",
    1.77,
    new Date(1989, 10, 29)
  ),
  new Rider(
    "Wout van Aert",
    "Jumbo-Visma",
    "Belgium",
    1.9,
    new Date(1994, 9, 15)
  ),
  new Rider(
    "Jonas Vingegaard",
    "Jumbo-Visma",
    "Denmark",
    1.75,
    new Date(1996, 12, 10)
  ),
  new Rider("Thomas De Gendt", "Lotto", "Belgium", 1.79, new Date(1986, 11, 6)),
  new Rider("Caleb Ewan", "Lotto", "Australia", 1.67, new Date(1994, 7, 11)),
  new Rider("Philippe Gilbert", "Lotto", "Belgium", 1.78, new Date(1982, 7, 5)),
  new Rider(
    "Matteo Jorgenson",
    "Movistar",
    "United States",
    1.9,
    new Date(1999, 7, 1)
  ),
  new Rider("Enric Mas", "Movistar", "Spain", 1.77, new Date(1995, 1, 7)),
  new Rider(
    "Alejandro Valverde",
    "Movistar",
    "Spain",
    1.77,
    new Date(1980, 4, 25)
  ),
  new Rider(
    "Julian Alaphilippe",
    "Quick-Step",
    "France",
    1.73,
    new Date(1992, 6, 11)
  ),
  new Rider(
    "Kasper Asgreen",
    "Quick-Step",
    "Denmark",
    1.92,
    new Date(1995, 2, 8)
  ),
  new Rider(
    "Davide Ballerini",
    "Quick-Step",
    "Italy",
    1.83,
    new Date(1994, 9, 21)
  ),
  new Rider(
    "Mark Cavendish",
    "Quick-Step",
    "Great Britain",
    1.75,
    new Date(1985, 5, 21)
  ),
  new Rider(
    "Remco Evenepoel",
    "Quick-Step",
    "Belgium",
    1.71,
    new Date(2000, 1, 25)
  ),
  new Rider(
    "Fabio Jakobsen",
    "Quick-Step",
    "Netherlands",
    1.81,
    new Date(1996, 8, 31)
  ),
  new Rider(
    "Michael Morkov",
    "Quick-Step",
    "Denmark",
    1.83,
    new Date(1985, 4, 30)
  ),
  new Rider(
    "Lawson Craddock",
    "BikeExchange",
    "United States",
    1.78,
    new Date(1992, 2, 30)
  ),
  new Rider(
    "Dylan Groenewegen",
    "BikeExchange",
    "Netherlands",
    1.77,
    new Date(1993, 6, 21)
  ),
  new Rider(
    "Michael Matthews",
    "BikeExchange",
    "Australia",
    1.78,
    new Date(1990, 9, 26)
  ),
  new Rider(
    "Simon Yates",
    "BikeExchange",
    "Great Britain",
    1.72,
    new Date(1992, 8, 7)
  ),
  new Rider(
    "Thymen Arensman",
    "DSM",
    "Netherlands",
    1.92,
    new Date(1999, 12, 4)
  ),
  new Rider("Romain Bardet", "DSM", "France", 1.84, new Date(1990, 11, 9)),
  new Rider("Cees Bol", "DSM", "Netherlands", 1.94, new Date(1995, 7, 27)),
  new Rider(
    "Soren Kragh Andersen",
    "DSM",
    "Denmark",
    1.78,
    new Date(1994, 8, 10)
  ),
  new Rider("Giulio Ciccone", "Trek", "Italy", 1.76, new Date(1994, 12, 20)),
  new Rider("Daan Hoole", "Trek", "Netherlands", 1.98, new Date(1999, 2, 22)),
  new Rider("Mads Pedersen", "Trek", "Denmark", 1.79, new Date(1995, 12, 18)),
  new Rider(
    "Quinn Simmons",
    "Trek",
    "United States",
    1.82,
    new Date(2001, 5, 8)
  ),
  new Rider("Jasper Stuyven", "Trek", "Belgium", 1.86, new Date(1992, 4, 17)),
  new Rider("Joao Almeida", "UAE", "Portugal", 1.78, new Date(1998, 8, 5)),
  new Rider("George Bennett", "UAE", "New Zealand", 1.8, new Date(1990, 4, 7)),
  new Rider("Davide Formolo", "UAE", "Italy", 1.81, new Date(1992, 10, 25)),
  new Rider("Marc Hirschi", "UAE", "Switzerland", 1.74, new Date(1998, 8, 24)),
  new Rider("Rafal Majka", "UAE", "Poland", 1.73, new Date(1989, 9, 12)),
  new Rider(
    "Brandon McNulty",
    "UAE",
    "United States",
    1.83,
    new Date(1998, 4, 2)
  ),
  new Rider("Tadej Pogacar", "UAE", "Slovenia", 1.76, new Date(1998, 9, 21)),
  new Rider("Marc Soler", "UAE", "Spain", 1.86, new Date(1993, 11, 22)),
  new Rider("Matteo Trentin", "UAE", "Italy", 1.79, new Date(1989, 8, 2)),
];
