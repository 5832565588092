import { Country } from "../model/Country";
import { solution } from "./rider_logic";

const europe = [
  "Belgium",
  "Spain",
  "Netherlands",
  "Ireland",
  "Germany",
  "France",
  "Great Britain",
  "Denmark",
  "Russia",
  "Czech Republic",
  "Slovenia",
  "Italy",
  "Portugal",
  "Switzerland",
  "Poland",
];

const southAmerica = ["Columbia", "Equador"];
const nortAmerica = ["United States", "Canada"];
const australia = ["Australia", "New Zealand"];

export const isSameContinent = (country: Country) => {
  if (europe.includes(country) && europe.includes(solution.nationality))
    return true;
  else if (
    southAmerica.includes(country) &&
    southAmerica.includes(solution.nationality)
  )
    return true;
  else if (
    nortAmerica.includes(country) &&
    nortAmerica.includes(solution.nationality)
  )
    return true;
  else if (
    australia.includes(country) &&
    australia.includes(solution.nationality)
  )
    return true;
  return false;
};
