import Rider from "../model/Rider";
import { getAge, solution } from "./rider_logic";

export type CharStatus = "absent" | "present" | "correct";

const checkNames = (guess: string, solution: string): CharStatus => {
  if (guess === solution) return "correct";
  else return "absent";
};

const checkTeams = (guess: string, solution: string): CharStatus => {
  if (guess === solution) return "correct";
  else return "absent";
};

const checkNationality = (guess: string, solution: string): CharStatus => {
  if (guess === solution) return "correct";
  else return "absent";
};

const checkHeight = (guess: number, solution: number): CharStatus => {
  const difference = Math.abs(solution - guess);

  if (guess === solution) return "correct";
  else if (difference < 0.5) return "present";
  else return "absent";
};

const checkAge = (guess: number, solution: number): CharStatus => {
  const difference = Math.abs(solution - guess);

  if (guess === solution) return "correct";
  else if (difference < 2) return "present";
  else return "absent";
};

export const getGuessStatuses = (guess: Rider): CharStatus[] => {
  const statuses: CharStatus[] = Array.from(Array(5));
  statuses[0] = checkNames(guess.name, solution.name);
  statuses[1] = checkTeams(guess.team, solution.team);
  statuses[2] = checkNationality(guess.nationality, solution.nationality);
  statuses[3] = checkHeight(guess.height, solution.height);
  statuses[4] = checkAge(getAge(guess.birthDate), getAge(solution.birthDate));

  return statuses;
};
