import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MAX_CHALLENGES } from "../../constants/settings";
import { useState } from "react";
import Rider from "../../model/Rider";
import { riders } from "../../constants/riders";

type Props = {
  onCharacterGuess: (character: Rider) => void;
  guesses: Rider[];
  isGameWon: boolean;
};

export const NameInput = ({ onCharacterGuess, guesses, isGameWon }: Props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  //const [label, setLabel] = React.useState("Character Guess 1");
  let label = guesses.length;

  const onChange = (event: any, newValue: Rider | null) => {
    if (newValue != null) onCharacterGuess(newValue);
    setInputValue("");
  };

  const displayLabel = () => {
    if (label > MAX_CHALLENGES - 1) {
      return "Out of Guesses";
    } else return "Rider Guess " + (label + 1) + " of " + MAX_CHALLENGES;
  };

  return (
    <div>
      <Autocomplete
        blurOnSelect
        open={open}
        onOpen={() => {
          // only open when in focus and inputValue is not empty
          if (inputValue) {
            setOpen(true);
          }
        }}
        onClose={() => setOpen(false)}
        disabled={label > MAX_CHALLENGES - 1 || isGameWon}
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          // only open when inputValue is not empty after the user typed something
          if (!newInputValue) {
            setOpen(false);
          }
        }}
        onChange={onChange}
        id="combo-box-demo"
        options={riders}
        getOptionLabel={(option) => option.name}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label={displayLabel()} />
        )}
      />
      {/* <button onClick={() => setValue(null)}>Reset autocomplete</button> */}
    </div>
  );
};
