import { useState } from "react";
import { BaseModal } from "./BaseModal";
import { BuyMeACoffeeModal } from "./BuyMeACoffeeModal";
import venmoSvg from "../../images/vemo.svg";
import btcSvg from "../../images/Bitcoin.png";
import cashSvg from "../../images/Cash.svg";

const loadVenmo = () => {
  window.location.href = "https://venmo.com/zachary-bennett-8?txn=pay";
  setTimeout(function () {
    window.location.href =
      "https://venmo.com/code?user_id=1888895109169152805&created=1657386216.683237&printed=1";
  }, 1000);
};

const loadCash = () => {
  window.location.href = "https://cash.app/$ZachayBennett";
};

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleCopy: () => void;
};

export const InfoModal = ({ isOpen, handleClose, handleCopy }: Props) => {
  const [isBuyMeModalOpen, setBuyMeModalOpen] = useState(false);
  const [isExampleOpen, setExampleOpen] = useState(false);
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-md text-gray-500 dark:text-gray-300">
        <div className="info-text">
          <b>Guess the mystery World Tour Rider</b> <br></br>
        </div>
        <br />
        <ul>
          <li>
            <div className="info-text">
              You have eight guesses to get it right
            </div>
          </li>
          <li>
            <div className="info-text">
              <span className="guess-correct" style={{ color: "white" }}>
                Green in any column
              </span>
              &nbsp;indicates a match!
            </div>
          </li>
          <li>
            <div className="info-text">
              <span className="guess-almost">
                Yellow in the <b>Height</b> or <b>Age</b> column
              </span>
              &nbsp;indicates this attribute is within 0.08 meters or 2 years of
              the mystery rider.
            </div>
          </li>
          <li>
            <div className="info-text">
              <span className="guess-almost">
                Yellow in the <b>Nationality</b> column
              </span>
              &nbsp;indicates this attribute is on the same continent as the
              mystery rider's country.
            </div>
          </li>
          <li>
            If you get stuck, try hitting <b>Get A Hint</b> after two guesses!
          </li>
          <li>
            <br></br>
            <div>
              <div className="border-solid border-2 rounded-lg border-indigo-600">
                <span className="text-gray-900 dark:text-gray-100 font-medium">
                  Support the site
                </span>
                <div>
                  <button
                    className="focus:outline-none"
                    onClick={() => loadVenmo()}
                  >
                    <img className="venmo mx-1.5" src={venmoSvg} alt="" />
                  </button>
                  <button
                    className="focus:outline-none"
                    onClick={() => loadCash()}
                  >
                    <img className="venmo" src={cashSvg} alt="" />
                  </button>

                  <button
                    className="focus:outline-none"
                    onClick={() => setBuyMeModalOpen(true)}
                  >
                    <img className="venmo mx-2" src={btcSvg} alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div>
              Questions, comments, concerns, suggestions? Tweet us
              <a
                href="https://twitter.com/CyclingWordle"
                className="twitter-follow-button text-blue-500 border-transparent focus:outline-none"
                data-show-count="false"
              >
                &nbsp;@CyclingWordle
              </a>
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                // charset="utf-8"
              ></script>
            </div>
          </li>
        </ul>
      </p>

      {/* <button
        className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm focus:outline-none"
        onClick={() => setBuyMeModalOpen(true)}
      >
        ☕ Buy Me a Coffee
      </button> */}
      <BuyMeACoffeeModal
        isOpen={isBuyMeModalOpen}
        handleClose={() => setBuyMeModalOpen(false)}
        handleCopy={() => handleCopy()}
      />
    </BaseModal>
  );
};
