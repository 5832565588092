import { isSameContinent } from "../../lib/country_logic";
import { getAge, isWinningCharacter, solution } from "../../lib/rider_logic";
import Rider from "../../model/Rider";

type Props = {
  riderGuess: Rider;
};

export const Guess = ({ riderGuess }: Props) => {
  let correctName = riderGuess.name === solution.name;
  let correctTeam = riderGuess.team === solution.team;
  let correctNationality = riderGuess.nationality === solution.nationality;
  let correctHeight = riderGuess.height === solution.height;
  let correctAge = getAge(riderGuess.birthDate) === getAge(solution.birthDate);

  let almostCorrectHeight = () => {
    const dif = Math.abs(riderGuess.height - solution.height);
    if (dif < 0.08) {
      return true;
    }
    return false;
  };

  let almostCorrectAge = () => {
    const dif = Math.abs(
      getAge(riderGuess.birthDate) - getAge(solution.birthDate)
    );
    if (dif < 3) return true;
    return false;
  };

  let rightContinent = () => {
    if (isSameContinent(riderGuess.nationality)) return true;
    return false;
  };

  let taller = () => {
    if (riderGuess.height - solution.height < 0) return true;
    return false;
  };
  let shorter = () => {
    if (riderGuess.height - solution.height > 0) return true;
    return false;
  };

  let older = () => {
    if (getAge(riderGuess.birthDate) - getAge(solution.birthDate) < 0)
      return true;
    return false;
  };

  let younger = () => {
    if (getAge(riderGuess.birthDate) - getAge(solution.birthDate) > 0)
      return true;
    return false;
  };

  return (
    <div className="guess grid grid-cols-4 sm:grid-cols-5  border-b-2 border-slate-800 items-center">
      <div
        className={
          "character-tile justify-left sm:justify-center col-span-4 sm:col-span-1 bg-stone-100 " +
          (correctName ? "guess-correct" : "")
        }
      >
        <div className="character-guess text-left sm:text-center  sm:text-sm font-bold sm:font-normal">
          {riderGuess.name}
        </div>
      </div>
      <div
        className={
          "character-tile justify-center " +
          (correctTeam ? "guess-correct" : "")
        }
      >
        <div className="character-guess sm:text-sm">{riderGuess.team}</div>
      </div>
      <div
        className={
          "character-tile justify-center " +
          (correctNationality ? "guess-correct" : "") +
          " " +
          (rightContinent() && !correctNationality ? "guess-almost" : "")
        }
      >
        <div className="character-guess sm:text-sm">
          {riderGuess.nationality}
        </div>
      </div>
      <div
        className={
          "character-tile justify-center " +
          (correctHeight ? "guess-correct" : "") +
          " " +
          (almostCorrectHeight() && !correctHeight ? "guess-almost" : "")
        }
      >
        <div className="character-guess sm:text-sm">
          {riderGuess.height}
          {shorter() && <span>↓</span>}
          {taller() && <span>↑</span>}
        </div>
      </div>

      <div
        className={
          "character-tile justify-center " +
          (correctAge ? "guess-correct" : "") +
          " " +
          (almostCorrectAge() && !correctAge ? "guess-almost" : "")
        }
      >
        <div className="character-guess sm:text-sm">
          {getAge(riderGuess.birthDate)}
          {younger() && <span>↓</span>}
          {older() && <span>↑</span>}
        </div>
      </div>
    </div>
  );
};
