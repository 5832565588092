import { Country } from "./Country";

export default class Rider {
  name;
  team;
  nationality;
  height;
  birthDate;
  constructor(
    name: string,
    team:
      | "AG2R"
      | "Astana"
      | "Bahrain"
      | "Bora"
      | "Cofidis"
      | "EF"
      | "Groupama"
      | "Astana"
      | "Ineos"
      | "Intermarche"
      | "Israel"
      | "Jumbo-Visma"
      | "Lotto"
      | "Movistar"
      | "Quick-Step"
      | "BikeExchange"
      | "DSM"
      | "Trek"
      | "UAE",
    nationality: Country,
    height: number,
    birthDate: Date
  ) {
    this.name = name;
    this.team = team;
    this.nationality = nationality;
    this.height = height;
    this.birthDate = birthDate;
  }
}
