import { riders } from "../constants/riders";
import Rider from "../model/Rider";
import reorder from "./reorder";

export const isWinningCharacter = (character: Rider) => {
  return solution === character;
};

export function getAge(dateString: Date) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const getCharacterOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date(2022, 0).valueOf();
  const now = Date.now();
  const msInDay = 86400000;
  let index = Math.floor((now - epochMs) / msInDay);
  const nextday = (index + 1) * msInDay + epochMs;

  //override for testing
  // index = 1;
  const reorderedList = reorder(riders);

  return {
    solution: reorderedList[index % riders.length],
    solutionIndex: index,
    tomorrow: nextday,
  };
};

export const { solution, solutionIndex, tomorrow } = getCharacterOfDay();
