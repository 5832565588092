export default function reorder(array, first, second) {
  const result = [],
    swap = { unshift: "push", push: "unshift" };

  array
    .sort((a, b) => b - a)
    .forEach(
      (
        (fn) => (v) =>
          result[(fn = swap[fn])](v)
      )("push")
    );

  return result;
}
