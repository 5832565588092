import { BaseModal } from "./BaseModal";
import { solution } from "../../lib/rider_logic";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const HintModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Hint" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-md text-gray-500 dark:text-gray-300">
        <div className="info-text">
          Current Team: <br></br>
        </div>
        <div className="info-text">
          <b>{solution.team} </b>
          <br></br>
        </div>
      </p>
    </BaseModal>
  );
};
